<template>
  <el-dialog
    title="修改密码"
    :visible.sync="resetPwdDialogVis"
    width="30%"
    @close="closeDialog"
  >
    <div class="form-area">
      <el-form ref="form" :model="form" :rules="rule">
        <el-form-item prop="mobile">
          <el-input v-model="form.mobile" disabled>{{
            userData.mobile
          }}</el-input>
        </el-form-item>
        <el-form-item prop="code" >
          <el-col :span="16">
            <el-input
              v-model="form.code"
              placeholder="请输入5位验证码"
              maxlength="5"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            >
            </el-input>
          </el-col>
          <el-col :span="7"><el-button @click="clickToSend" class="get-code" :disabled="disabled">{{button_label}}</el-button></el-col>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入登录密码，6-20位且含数字和字母"
            maxlength="20"
            @keyup.native="restempty('form', 'password')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="passwordAgain">
          <el-input
            v-model="form.passwordAgain"
            placeholder="请再次确认登录密码"
            :disabled="isNotAgainAble"
            maxlength="20"
            @keyup.native="restempty('form', 'passwordAgain')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <button @click="resetPass" class="btn-comfirm">确认修改</button>
    </span>
  </el-dialog>
</template>

<script>
import { request } from '../../../../network';

export default {
  name: 'resetPwdDialog',
  data () {
    return {
      password: '',
      passwordAgain: '',
      resetPwdDialogVis: this.value,
      form: {
        mobile: this.userData.mobile,
        code: '',
        password: '',
        passwordAgain: ''
      },
      rule: {
        mobile: [
          {
            required: true,
            pattern: '^[1][3,4,5,6,7,8,9][0-9]{9}$',
            message: '请输入正确的11位手机号码',
            trigger: 'blur'
          }
        ],
        code: [ { required: true, message: '请输入验证码', trigger: 'blur' } ],
        password: [
          {
            required: true,
            validator: this.testPassWord,
            message: '请输入新密码(6-20位，须含数字和字母)',
            trigger: 'blur'
          }
        ],
        passwordAgain: [
          {
            required: true,
            validator: this.testPassWordAgain,
            trigger: 'blur'
          }
        ]
      },
      button_label: '获取验证码',
      disabled: false,
      isNotAgainAble: true
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    resetPass () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { data: res } = await request({
            method: 'POST',
            url: '/pcp/modify',
            data: {
              phone: this.form.mobile,
              code: this.form.code,
              passWord: this.password
            }
          });
          if (res.code === 501) return this.$message.error(res.message);
          if (res.code !== 200) return this.$message.error('密码重置失败');
          this.closeDialog();
          this.$message.success(res.message);
          this.$router.push('/login');
        }
      });
    },
    closeDialog () {
      this.resetPwdDialogVis = false;
      this.$refs.form.clearValidate();
      this.$emit('input', this.resetPwdDialogVis);
    },
    clickToSend () {
      this.$refs.form.validateField('mobile', async (validMes) => {
        if (validMes) {
          this.$message.error(validMes);
        } else {
          this.disabled = true;
          const { data: res } = await request({
            method: 'GET',
            url: '/pcp/verification',
            params: {
              phone: this.form.mobile,
              type: 'reset'
            }
          });
          if (res.code === 501) return this.$message.error(res.message);
          if (res.code !== 200) return this.$message.error('验证码发送失败');
          this.$message.success('验证码已发送，请注意查收');
          this.countdown();
        }
      });
    },
    testPassWord (rule, value, callback) {
      const testPaw = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,20}$/;
      if (!testPaw.test(this.password)) { return callback(new Error('请输入新密码(6-20位，须含数字和字母)')); }
      this.isNotAgainAble = false;
      callback();
    },
    testPassWordAgain (rule, value, callback) {
      if (this.passwordAgain !== this.password) { return callback(new Error('两次输入的密码不一致，请重新输入')); }
      callback();
    },
    restempty (fval, value) {
      this[fval][value] = this[fval][value].replace(/[, ]/g, '');
    },
    countdown () {
      let time = 60;
      const timer = setInterval(() => {
        if (time === 0 || this.form.mobile.length < 11) {
          clearInterval(timer);
          this.disabled = false;
          this.button_label = '获取验证码';
        } else {
          this.button_label = time + '秒后重试';
          this.disabled = true;
          time--;
        }
      }, 1000);
    }
  },
  watch: {
    value (newVal) {
      this.resetPwdDialogVis = newVal;
    },
    'form.password': {
      handler (newval, oldval) {
        let password = '';
        if (newval.length < oldval.length) {
          let delLength = oldval.length - newval.length;
          password = this.form.password.substr(0, password.length - delLength);
          this.password = this.password.slice(0, this.password.length - 1);
        } else {
          if (newval.charAt(newval.length - 1) !== '*') {
            password += newval.charAt(newval.length - 1);
            this.password += password;
            let res = newval.split('');
            res.forEach((element, index) => {
              res.splice(index, 1, '*');
            });
            this.form.password = res.join('');
          }
        }
      }
    },
    'form.passwordAgain': {
      handler (newval, oldval) {
        let password = '';
        if (newval.length < oldval.length) {
          let delLength = oldval.length - newval.length;
          password = this.form.passwordAgain.substr(0, password.length - delLength);
          this.passwordAgain = this.passwordAgain.slice(0, this.passwordAgain.length - 1);
        } else {
          if (newval.charAt(newval.length - 1) !== '*') {
            password += newval.charAt(newval.length - 1);
            this.passwordAgain += password;
            let res = newval.split('');
            res.forEach((element, index) => {
              res.splice(index, 1, '*');
            });
            this.form.passwordAgain = res.join('');
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
/deep/.el-dialog {
  width: 480px !important;
  height: 468px !important;
  background: #ffffff !important;
  border-radius: 5px !important;
}
/deep/.el-dialog__footer {
  text-align: center;
  // padding: 0px !important;
  padding-bottom: 20px;
}
/deep/.el-dialog__body {
  padding: 0;
}
.el-form-item:nth-child(1) /deep/ .el-input .el-input__inner {
  width: 320px;
  height: 50px;
}
.el-form-item:nth-child(2) /deep/ .el-input .el-input__inner {
  width: 210px;
  height: 50px;
}
.el-form-item:nth-child(2) /deep/.el-form-item__content {
  display: flex;
  width: 320px;
}
.el-form-item:nth-child(3) /deep/.el-input .el-input__inner {
  width: 320px;
  height: 50px;
}
.el-form-item:nth-child(4) /deep/.el-input .el-input__inner {
  width: 320px;
  height: 50px;
}
/deep/.el-form-item__error {
  width: 320px;
}
.get-code {
  // margin-left: -100px;
  width: 100px;
  height: 50px;
  // border: 1px solid #156ED0;
  border-radius: 5px;
  font-size: 14px;
  // color: #156ED0;
  background-color: #fff;
  cursor: pointer;
  margin-left: 6px;
}
// .get-code {
//   margin-left: -100px;
//   width: 100px;
//   height: 50px;
//   border: 1px solid #156ED0;
//   border-radius: 5px;
//   font-size: 14px;
//   color: #156ED0;
//   background-color: #fff;
//   cursor: pointer;
// }
/deep/.el-dialog__header {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.form-area {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.btn-comfirm {
  width: 160px;
  height: 48px;
  background: #156ED0;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
</style>
